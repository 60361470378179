import LanguageSwitcher from "@/components/LanguageSwitcher";
import HomePage from "./01-main-demo";
import BackToTop from "./backToTop";
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

export default function Home() {
  return (
    <>
      <main className="">
        <HomePage />
        <BackToTop />
      </main>
    </>
  );
}

// Add this function to preload translations
export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])), // Load the 'common' namespace
    },
  };
}

import PageHead from "../Head";
import HeaderStyleTen from "@/components/Header/HeaderStyle-Ten";
import Separator from "@/components/Common/Separator";
import MainDemo from "@/components/01-Main-Demo/01-Main-Demo";
import MobileMenu from "@/components/Header/MobileMenu";
import Cart from "@/components/Header/Offcanvas/Cart";

import Context from "@/context/Context";
import { Provider } from "react-redux";
import Store from "@/redux/store";
import FooterThree from "@/components/Footer/Footer-Three";

import { serverSideTranslations } from 'next-i18next/serverSideTranslations';


// import { useTranslation } from 'next-i18next';


const Home = () => {
  // const { t } = useTranslation('common');

  return (
    <>
      <PageHead title="MJDRI - Раванди Олимпиадаҳо дар Тоҷикистон" />

      <Provider store={Store}>
        <Context>
          <MobileMenu />
          <HeaderStyleTen headerSticky="rbt-sticky" headerType="" />
          <MainDemo />
          <div id="modal-root"></div> {/* Add this line */}
          <Separator />
          <FooterThree />
        </Context>
      </Provider>
    </>
  );
};

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])), // Load the 'common' namespace
    },
  };
}

export default Home;